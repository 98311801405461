.shadowCard {
  -webkit-box-shadow: 0px 4px 19px 5px rgba(138, 138, 138, 0.1);
  -moz-box-shadow: 0px 4px 19px 5px rgba(138, 138, 138, 0.1);
  box-shadow: 0px 4px 19px 5px rgba(138, 138, 138, 0.1);
}

.h3Dashboard {
  color: #4d426d;
  font-weight: 400;
  font-size: 28px;
  margin-left: 20px;
  margin-top: 9.5px;
  vertical-align: middle;
}

.cardHeader {
  padding: 20px !important;
  padding-left: 45px !important;
  background-color: #fcfcfc !important;
  border-color: #e6e6e6 !important;
  font-family: "Raleway" !important;
}

.cardBody {
  padding: 45px !important;
  padding-left: 45px !important;
  font-family: "Raleway" !important;
  color: #828aaf;
  font-size: 17px !important;
  font-weight: 500;
}

.buttonProva {
  background-color: #4a61ee;
  font-family: "Raleway" !important;
  color: #fff;
  padding: 10px 25px 10px 25px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  border: 2px #354cd5 solid;
}

.descricaoProva {
  color: #474747;
}

.dot {
  height: 50px;
  width: 50px;
  background-color: #287bef;
  border-radius: 50%;
  display: inline-block;
}

.shadowIcon {
  -webkit-box-shadow: 0px 4px 19px 5px rgba(138, 138, 138, 0.1);
  -moz-box-shadow: 0px 4px 19px 5px rgba(138, 138, 138, 0.1);
  box-shadow: 0px 4px 19px 5px rgba(138, 138, 138, 0.1);
}

.inputCheckedYes {
  background-color: "#E0F9EB";
  padding-top: "15px";
}

.disabled {
  background-color: #6c757d;
  color: #fff;
  padding: 10px 25px 10px 25px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  border: 2px #6c757d solid;
}

.shadow {
  -webkit-box-shadow: 3px 4px 20px 0px rgba(153, 153, 153, 0.22);
  -moz-box-shadow: 3px 4px 20px 0px rgba(153, 153, 153, 0.22);
  box-shadow: 3px 4px 20px 0px rgba(153, 153, 153, 0.22);
}
